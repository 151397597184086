import styled from 'styled-components';
import Colors from 'pubweb-smokey/dist/colors';

import {
  mobile_width_percentage,
  tablet_breakpoint,
  tablet_container_maxwidth,
  desktop_breakpoint,
  desktop_container_maxwidth,
} from 'pubweb-smokey/dist/components/GridSystem/_vars_widths.js';

export const NotFoundStyles = styled.div`
  /* constrained-width keeps things boxed in while still 
    letting a background image or color go all the way across.
    This is also in the base css, but I am also including it here,
    for use in other projects, such as newsroom.
     */
  .constrained-width {
    margin: 0 auto;
    width: ${mobile_width_percentage}%;
  }
  @media only screen and (min-width: ${tablet_breakpoint}px) {
    .constrained-width {
      max-width: ${tablet_container_maxwidth}px;
    }
  }
  @media only screen and (min-width: ${desktop_breakpoint}px) {
    .constrained-width {
      max-width: ${desktop_container_maxwidth}px;
    }
  }

  .not-found-container {
    margin-top: 30px;
  }
  .not-found-content {
    text-align: center;

    h2 {
      padding-top: 3px;
    }
    .try-these {
      padding-top: 3px;
    }
  }
  .link-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 50px 0;
    a {
      color: ${Colors.primary.claytonBlue.standard};
      text-decoration: none;
      .link {
        border-right: 1px solid ${Colors.accent.grey4.standard};
        flex-grow: 1;
        text-align: center;
        padding-right: 16px;
        .icon {
          color: ${Colors.primary.claytonBlue.standard};
          svg {
            display: block;
            margin: 6px auto;
            width: 20px;
            height: 20px;
            path {
              fill: ${Colors.primary.claytonBlue.standard};
            }
          }
        }
      }
      .third-link {
        border-right: none;
      }
    }
  }

  .not-found-illustration {
    .not-home-illustration {
      width: 100%;
      height: 100%;
    }
  }

  @media only screen and (max-width: 320px) {
    .label.large {
      font-size: 10px;
    }
  }

  @media only screen and (min-width: 500px) {
    .site-container {
      padding: 25px;
    }
    .link-container {
      border: 1px solid ${Colors.accent.grey4.standard};
      .second-anchor {
        width: 33%;
      }
      a {
        .link {
          padding: 16px 0;
        }
        .first-link {
          border-right: none;
        }
        .second-link {
          border-left: 1px solid ${Colors.accent.grey4.standard};
          border-right: 1px solid ${Colors.accent.grey4.standard};
        }
      }
    }
  }

  @media only screen and (min-width: 1000px) {
    .not-found-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 486px;
      margin-top: 105px;
    }

    .not-found-content {
      width: 400px;
    }
    .not-found-illustration {
      .not-home-illustration {
        padding-left: 60px;
        width: 500px;
        height: 487px;
        margin-top: 65px;
      }
    }
    .link-container {
      display: block;
      a {
        .second-link {
          border-left: none;
          border-right: none;
          border-top: 1px solid ${Colors.accent.grey4.standard};
          border-bottom: 1px solid ${Colors.accent.grey4.standard};
        }
      }
    }
  }

  @media only screen and (min-width: 1200px) {
    .not-found-illustration {
      .not-home-illustration {
        width: 600px;
        margin-top: 50px;
      }
    }
  }

  @media only screen and (min-width: 1400px) {
    .not-found-illustration {
      .not-home-illustration {
        width: 700px;
        margin-top: 0;
      }
    }
  }
`;
