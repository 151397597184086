import React from 'react';
import PropTypes from 'prop-types';
import HomeSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/home.svg';
import MountainsSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/mountains.svg';
import PaintbrushSvg from 'pubweb-smokey/dist/images/svg/iconography-16x16/paintbrush.svg';
import Illustration from './images/404_v2.svg';
import SectionLink from '../Shared/SectionLink/SectionLink';

import { NotFoundStyles } from './NotFound.styled';

const NotFoundContent = ({
  internalLink = true,
  addSiteUrl = false,
  siteUrlOverride = '',
}) => {
  /* eslint-disable react/no-unescaped-entities */
  return (
    <NotFoundStyles>
      <div className="not-found-container constrained-width">
        <div className="not-found-content">
          <p className="error label large">404 error</p>
          <h2>Oops, nobody's home!</h2>
          <p className="try-these">Try these helpful links instead.</p>
          <div className="link-container">
            <SectionLink
              internalLink={internalLink}
              siteUrlOverride={siteUrlOverride}
              addSiteUrl={addSiteUrl}
              className="label large"
              to="/find-a-home/"
            >
              <div className="link first-link find-a-home-link">
                <div className="icon">
                  <HomeSvg viewBox="0 0 16 16" />
                </div>
                find a home
              </div>
            </SectionLink>
            <SectionLink
              internalLink={internalLink}
              addSiteUrl={addSiteUrl}
              siteUrlOverride={siteUrlOverride}
              className="label large second-anchor"
              to="/find-land/"
            >
              <div className="link second-link find-land-link">
                <div className="icon">
                  <MountainsSvg viewBox="0 0 16 16" />
                </div>
                find land
              </div>
            </SectionLink>
            <SectionLink
              internalLink={internalLink}
              addSiteUrl={addSiteUrl}
              siteUrlOverride={siteUrlOverride}
              to="/get-inspired/"
              className="label large"
            >
              <div className="link third-link get-inspired-link">
                <div className="icon">
                  <PaintbrushSvg viewBox="0 0 16 16" />
                </div>
                get inspired
              </div>
            </SectionLink>
          </div>
        </div>
        <div className="not-found-illustration">
          <Illustration className="not-home-illustration" />
        </div>
      </div>
    </NotFoundStyles>
  );
  /* eslint-enable react/no-unescaped-entities */
};

NotFoundContent.propTypes = {
  addSiteUrl: PropTypes.bool,
  internalLink: PropTypes.bool,
  siteUrlOverride: PropTypes.string,
};

export default NotFoundContent;
